import { SvgIcon } from '@mui/material'

const PeopleIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 20" {...props}>
      <path d="M14.6084 6.47501C14.55 6.46667 14.4917 6.46667 14.4334 6.47501C13.1417 6.43334 12.1167 5.37501 12.1167 4.07501C12.1167 2.75001 13.1917 1.66667 14.525 1.66667C15.85 1.66667 16.9334 2.74167 16.9334 4.07501C16.925 5.37501 15.9 6.43334 14.6084 6.47501Z" />
      <path d="M17.3249 12.25C16.3916 12.875 15.0833 13.1083 13.8749 12.95C14.1916 12.2667 14.3583 11.5083 14.3666 10.7083C14.3666 9.875 14.1833 9.08333 13.8333 8.39167C15.0666 8.225 16.3749 8.45833 17.3166 9.08333C18.6333 9.95 18.6333 11.375 17.3249 12.25Z" />
      <path d="M5.36672 6.47501C5.42505 6.46667 5.48338 6.46667 5.54172 6.47501C6.83338 6.43334 7.85838 5.37501 7.85838 4.07501C7.85838 2.74167 6.78338 1.66667 5.45005 1.66667C4.12505 1.66667 3.05005 2.74167 3.05005 4.07501C3.05005 5.37501 4.07505 6.43334 5.36672 6.47501Z" />
      <path d="M5.45843 10.7083C5.45843 11.5167 5.63343 12.2833 5.9501 12.975C4.7751 13.1 3.5501 12.85 2.6501 12.2583C1.33343 11.3833 1.33343 9.95834 2.6501 9.08334C3.54176 8.48334 4.8001 8.24167 5.98343 8.37501C5.64176 9.07501 5.45843 9.86667 5.45843 10.7083Z" />
      <path d="M10.1 13.225C10.0333 13.2167 9.95833 13.2167 9.88333 13.225C8.35 13.175 7.125 11.9167 7.125 10.3667C7.13333 8.78333 8.40833 7.5 10 7.5C11.5833 7.5 12.8667 8.78333 12.8667 10.3667C12.8583 11.9167 11.6417 13.175 10.1 13.225Z" />
      <path d="M7.39175 14.95C6.13341 15.7917 6.13341 17.175 7.39175 18.0083C8.82508 18.9667 11.1751 18.9667 12.6084 18.0083C13.8667 17.1667 13.8667 15.7833 12.6084 14.95C11.1834 13.9917 8.83341 13.9917 7.39175 14.95Z" />
    </SvgIcon>
  )
}

export default PeopleIcon
