import { SvgIcon } from '@mui/material'

const DropdownArrowIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none', ...sx }}
      width="10"
      height="12"
      viewBox="0 -3 15 25"
      {...props}
    >
      <path d="M4.30792 11.707L8.72084 11.707L12.6946 11.707C13.3746 11.707 13.7146 10.8854 13.2329 10.4037L9.56376 6.73452C8.97584 6.14661 8.01959 6.14661 7.43167 6.73452L6.03626 8.12994L3.7625 10.4037C3.28792 10.8854 3.62792 11.707 4.30792 11.707Z" />
    </SvgIcon>
  )
}

export default DropdownArrowIcon
