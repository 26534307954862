import { useEffect, useMemo } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Typography, Box } from '@mui/material'

import ArrowLeftIcon from 'assets/svg/ArrowLeftIcon'

import { privatePaths } from 'config/routes'
import HeaderStore from 'stores/HeaderStore'
import { observer } from 'mobx-react-lite'

const Title = ({ classes, active, params, navigate }) => {
  const role = localStorage.getItem('role')
  const {
    subFranchiseeId,
    giftId,
    massEmailDetailId,
    merchantId,
    notificationsDataId,
    giftsTab,
    allGiftsGiftId,
    merchantGiftId,
    merchantTab,
    giftTab,
    promotionId
  } = useParams()

  const { pathname } = useLocation()

  const navigatePath = useMemo(() => {
    if (subFranchiseeId && !pathname.includes('map')) {
      return privatePaths[role]?.sub_franchisees
    }
    if (
      giftId &&
      role === 'franchisee' &&
      params.merchantTab !== 'allMerchants'
    ) {
      return `/franchisee/merchant/${params.merchantId}`
    }
    if (merchantId && giftId && params.merchantTab !== 'allMerchants') {
      return -1
    } else if (merchantId && giftsTab) {
      return -2
    } else if (giftsTab && allGiftsGiftId) {
      return -1
    } else if (merchantId && role !== 'superuser') {
      return `${privatePaths[role]?.merchant}`
    }
    if (promotionId) {
      return -1
    }
    if (giftId && role === 'subfranchisee') {
      if (giftTab === 'promotions') {
        return '/sub_franchisee/gifts/promotions'
      } else {
        return privatePaths[role]?.gifts
      }
    }
    if (massEmailDetailId) {
      return privatePaths[role]?.massEmail
    }
    if (
      params.merchantTab === 'allMerchants' &&
      params.merchantId &&
      params.giftId &&
      role === 'franchisee'
    ) {
      return `/franchisee/merchant/allMerchants/${merchantId}/gifts`
    }
    if (notificationsDataId) {
      return privatePaths[role]?.pushNotifications
    }
    if (
      params.franchiseeId &&
      params['*'] &&
      role === 'superuser' &&
      !params.subfranchiseeId
    ) {
      return privatePaths.superuser.franchisee
    }
    if (
      params.userid &&
      params['*'] === 'personalInfo' &&
      role === 'superuser' &&
      !params.subfranchiseeId
    ) {
      return privatePaths.superuser.contactManager
    }
    if (
      params.userid &&
      params['*'] === 'history' &&
      role === 'superuser'
    ) {
      return privatePaths.superuser.financials
    }
    if (params.franchiseeId && params.subfranchiseeId && params.userid && role === 'superuser') {
      return `/superuser/franchisee/${params.franchiseeId}/user/${params.userid}/subfranschisees`
    }
    if (params.userid && params.subfranchiseeId && role === 'superuser') {
      return `/superuser/contact_manager/${params.userid}/subfranchisee`
    }
    if (params.userid && params.franchiseeId && params.merchantId && role === 'superuser') {
      return `/superuser/franchisee/${params.franchiseeId}/user/${params.userid}/merchants`
    }
    if (params.merchantId && params.userid && !params.franchiseeId && role === 'superuser') {
      return `/superuser/contact_manager/${params.userid}/merchants`
    }
    if (giftId && role === 'superuser' && pathname.includes('superuser/gifts')){
      return privatePaths.superuser.gifts
    }
    if (merchantId && role === 'superuser' && params.merchantTab){
      return `/superuser/merchants/${params.merchantTab}`
    }
    if (params.franchiseeId && params.merchantId && role === 'superuser') {
      return `/superuser/franchisee/${params.franchiseeId}/merchants`
    }
    if (params.userid && params.subfranchiseeId && params.franchiseeId && role === 'superuser') {
      return `/superuser/franchisee/${params.franchiseeId}/user/${params.userid}/subfranchisees`
    }
    if (merchantGiftId) {
      return '/merchant/my_gifts/allGifts'
    }
    if (role === 'merchant' && giftTab) {
      return '/merchant/my_gifts/allGifts'
    }
    if (merchantTab && merchantId && role === 'subfranchisee') {
      return privatePaths.subfranchisee.merchant
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subFranchiseeId,
    giftId,
    massEmailDetailId,
    merchantId,
    notificationsDataId,
    params.franchiseeId,
    params.userid,
    giftsTab,
    allGiftsGiftId,
    params.subfranchiseeId,
    params.merchantId,
    merchantGiftId,
    merchantTab,
    giftTab,
    promotionId
  ])
  const { titleDetails, clearTitle } = HeaderStore

  useEffect(() => {
    return () => clearTitle()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const title = () => {
    const adminFranchisee =
      params.franchiseeId &&
      params.subfranchiseeId &&
      !params.franchiseeTab &&
      role === 'superuser'

    const adminMerchant =
      params.franchiseeId &&
      params.merchantId &&
      !params.franchiseeTab &&
      role === 'superuser'

    if (
      active?.title &&
      !titleDetails &&
      !params?.subFranchiseeId &&
      !params?.notificationsDataId
    ) {
      return (
        <Typography variant="h3" className="mainTitle">
          {active?.title === 'Gifts' && pathname.includes('create_gift')
            ? 'Gift name'
            : active?.title}
        </Typography>
      )
    } else if (
      active?.title &&
      titleDetails &&
      !adminFranchisee &&
      !adminMerchant
    ) {
      const isGiftDetailsPage =
        active?.title === 'Gifts' || active?.title === 'All Gifts'
      const isSubfranchiseeDetailsPage = active?.title === 'subFranchisees'
      const isMerchantDetailsPage = active?.title === 'Merchant'
      const title =
        isGiftDetailsPage || isMerchantDetailsPage
          ? null
          : isSubfranchiseeDetailsPage
          ? 'Subfranchisee:'
          : `${active?.title}:`
      return (
        <>
          <Typography variant="h3" className="mainTitle">
            {title}
            &nbsp;
          </Typography>
          <Typography
            variant="h3"
            className={isGiftDetailsPage ? 'mainTitle' : 'titleDetails'}
          >
            {titleDetails}
          </Typography>
        </>
      )
    } else if (params.notificationsDataId) {
      return (
        <Typography variant="h3" className="mainTitle">
          Notifications Data
        </Typography>
      )
    } else if (subFranchiseeId && pathname.includes('map')) {
      return (
        <Typography variant="h3" className="mainTitle">
          Set SubFranchisee Area
        </Typography>
      )
    }
    if (params.franchiseeId && params.subfranchiseeId && role === 'superuser') {
      return (
        <>
          <Typography variant="h3" className="mainTitle">
            Subfranchisee:&nbsp;
          </Typography>
          <Typography variant="h3">{titleDetails}</Typography>
        </>
      )
    }
    if (params.franchiseeId && params.merchantId && role === 'superuser') {
      return (
        <>
          <Typography variant="h3" className="mainTitle">
            Merchant:&nbsp;
          </Typography>
          <Typography variant="h3">{titleDetails}</Typography>
        </>
      )
    }
  }

  return (
    <Box sx={classes.headerTitle}>
      {navigatePath && (
        <ArrowLeftIcon
          onClick={() => navigate(navigatePath)}
          sx={classes.backIcon}
        />
      )}
      {title()}
    </Box>
  )
}

export default observer(Title)
