import { SvgIcon } from '@mui/material'

const SettingsIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 20" {...props}>
      <path d="M16.7501 7.68333C15.2417 7.68333 14.6251 6.61666 15.3751 5.30833C15.8084 4.55 15.5501 3.58333 14.7917 3.15L13.3501 2.325C12.6917 1.93333 11.8417 2.16666 11.4501 2.825L11.3584 2.98333C10.6084 4.29166 9.37508 4.29166 8.61675 2.98333L8.52508 2.825C8.15008 2.16666 7.30008 1.93333 6.64175 2.325L5.20008 3.15C4.44175 3.58333 4.18341 4.55833 4.61675 5.31666C5.37508 6.61666 4.75841 7.68333 3.25008 7.68333C2.38341 7.68333 1.66675 8.39166 1.66675 9.26666V10.7333C1.66675 11.6 2.37508 12.3167 3.25008 12.3167C4.75841 12.3167 5.37508 13.3833 4.61675 14.6917C4.18341 15.45 4.44175 16.4167 5.20008 16.85L6.64175 17.675C7.30008 18.0667 8.15008 17.8333 8.54175 17.175L8.63341 17.0167C9.38341 15.7083 10.6167 15.7083 11.3751 17.0167L11.4667 17.175C11.8584 17.8333 12.7084 18.0667 13.3667 17.675L14.8084 16.85C15.5667 16.4167 15.8251 15.4417 15.3917 14.6917C14.6334 13.3833 15.2501 12.3167 16.7584 12.3167C17.6251 12.3167 18.3417 11.6083 18.3417 10.7333V9.26666C18.3334 8.4 17.6251 7.68333 16.7501 7.68333ZM10.0001 12.7083C8.50841 12.7083 7.29175 11.4917 7.29175 10C7.29175 8.50833 8.50841 7.29166 10.0001 7.29166C11.4917 7.29166 12.7084 8.50833 12.7084 10C12.7084 11.4917 11.4917 12.7083 10.0001 12.7083Z" />
    </SvgIcon>
  )
}

export default SettingsIcon
