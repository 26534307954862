import { SvgIcon } from '@mui/material'

const GiftIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 20" {...props}>
      <path d="M16.6666 9.99999V15C16.6666 16.8417 15.1749 18.3333 13.3333 18.3333H6.66659C4.82492 18.3333 3.33325 16.8417 3.33325 15V9.99999C3.33325 9.54166 3.70825 9.16666 4.16659 9.16666H5.80825C6.26659 9.16666 6.64159 9.54166 6.64159 9.99999V12.6167C6.64159 13.2333 6.98325 13.8 7.52492 14.0917C7.76658 14.225 8.03325 14.2917 8.30825 14.2917C8.62492 14.2917 8.94159 14.2 9.21659 14.0167L10.0083 13.5L10.7416 13.9917C11.2499 14.3333 11.8999 14.375 12.4416 14.0833C12.9916 13.7917 13.3333 13.2333 13.3333 12.6083V9.99999C13.3333 9.54166 13.7083 9.16666 14.1666 9.16666H15.8333C16.2916 9.16666 16.6666 9.54166 16.6666 9.99999Z" />
      <path d="M17.9166 5.83332V6.66666C17.9166 7.58332 17.4749 8.33332 16.2499 8.33332H3.74992C2.47492 8.33332 2.08325 7.58332 2.08325 6.66666V5.83332C2.08325 4.91666 2.47492 4.16666 3.74992 4.16666H16.2499C17.4749 4.16666 17.9166 4.91666 17.9166 5.83332Z" />
      <path d="M9.69998 4.16668H5.09998C4.81665 3.85835 4.82498 3.38335 5.12498 3.08335L6.30832 1.90001C6.61665 1.59168 7.12498 1.59168 7.43332 1.90001L9.69998 4.16668Z" />
      <path d="M14.8917 4.16668H10.2917L12.5584 1.90001C12.8667 1.59168 13.3751 1.59168 13.6834 1.90001L14.8667 3.08335C15.1667 3.38335 15.1751 3.85835 14.8917 4.16668Z" />
      <path d="M11.6416 9.16666C12.1 9.16666 12.475 9.54166 12.475 9.99999V12.6083C12.475 13.275 11.7333 13.675 11.1833 13.3L10.4333 12.8C10.1583 12.6167 9.79995 12.6167 9.51662 12.8L8.73328 13.3167C8.18328 13.6833 7.44995 13.2833 7.44995 12.625V9.99999C7.44995 9.54166 7.82495 9.16666 8.28328 9.16666H11.6416Z" />
    </SvgIcon>
  )
}

export default GiftIcon
