import { SvgIcon } from '@mui/material'

const LogoWithText = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none !important', ...sx }}
      viewBox="0 0 116 40"
      {...props}
    >
      <path
        d="M21.8076 18.7284L36.4365 14.7733L35.2174 32.1151L21.8076 39.1127V18.7284Z"
        fill="#D7AFA8"
      />
      <path
        d="M20.5886 18.7284L5.95978 14.7733L7.17885 32.1151L20.5886 39.1127V18.7284Z"
        fill="#E4DC8E"
      />
      <path
        d="M20.7571 8.55941L35.123 13.8383L20.9632 17.9355L7.29469 13.9356L20.7571 8.55941Z"
        fill="#C5E498"
      />
      <path
        d="M20.7192 9.53987C21.8992 10.4324 22.6625 11.3981 22.9663 12.2197C23.2773 13.061 23.0759 13.6298 22.6229 13.9136C22.1698 14.1973 21.3281 14.2818 20.1405 13.9895C18.9806 13.704 17.6556 13.0901 16.4756 12.1976C15.2957 11.3051 14.5323 10.3394 14.2286 9.51772C13.9175 8.67648 14.1189 8.10763 14.5719 7.82388C15.025 7.54014 15.8668 7.45568 17.0543 7.74796C18.2142 8.03344 19.5392 8.64734 20.7192 9.53987Z"
        fill="white"
        stroke="#D7AFA8"
        strokeWidth="1.22137"
      />
      <path
        d="M22.2662 9.53987C21.0862 10.4324 20.3229 11.3981 20.0191 12.2197C19.7081 13.061 19.9094 13.6298 20.3625 13.9136C20.8155 14.1973 21.6573 14.2818 22.8448 13.9895C24.0047 13.704 25.3297 13.0901 26.5097 12.1976C27.6897 11.3051 28.453 10.3394 28.7568 9.51772C29.0678 8.67648 28.8665 8.10763 28.4134 7.82388C27.9604 7.54014 27.1186 7.45568 25.931 7.74796C24.7712 8.03344 23.4461 8.64734 22.2662 9.53987Z"
        fill="white"
        stroke="#E4DC8E"
        strokeWidth="1.22137"
      />
      <path
        d="M41.0137 23.1414C41.6741 21.614 41.8061 18.5591 37.0518 18.5591"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M3.53076 34.0957C4.28676 34.5968 6.22879 35.7516 7.94893 36.3619"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M11.4731 37.6119C12.3615 37.8756 14.5985 38.4267 16.4402 38.5222"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M20.2896 38.9349C21.2163 38.83 23.4977 38.4636 25.2098 37.8375"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M29.9502 36.1532C30.7677 35.7418 32.736 34.6287 34.0698 33.4673"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M37.3564 29.9321C37.9239 29.2562 39.2361 27.5166 39.946 25.965"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M5.34678 18.9777C3.77215 19.3942 0.775269 20.6995 1.3848 22.5879"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M1.38428 24.9465C1.89222 25.9648 3.48717 27.8348 5.80341 27.1682"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M9.61475 25.9185C10.0719 25.7797 11.9005 25.502 13.8815 24.8077"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M18.3946 22.9557C19.0647 22.7269 21.3505 22.0326 23.2264 20.9218"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M27.3181 18.7785C28.1507 18.3062 30.1317 17.4731 31.8654 16.2578"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M35.1792 13.8074C35.7394 13.2375 37.6066 11.9813 38.972 10.4047"
        stroke="#80A9BF"
        strokeWidth="0.610687"
      />
      <path
        d="M39.229 10.2873V7.89259L43.0543 4.98004L41.5631 9.12233L39.229 10.2873Z"
        fill="#80A9BF"
      />
      <path
        d="M46.0366 1.54971L37.2839 7.11591L39.2938 8.4751L42.7301 5.56255L40.0718 8.79872L42.341 9.76956L46.0366 1.54971Z"
        fill="#4E9AC2"
      />
      <path
        d="M58.6541 28.5819C57.5013 29.75 56.0905 30.334 54.4217 30.334C52.7529 30.334 51.3422 29.75 50.1894 28.5819C49.0366 27.4139 48.4602 25.998 48.4602 24.3341C48.4602 22.6593 49.0366 21.2433 50.1894 20.0863C51.3422 18.9183 52.7529 18.3343 54.4217 18.3343C56.0905 18.3343 57.5013 18.9183 58.6541 20.0863C59.8178 21.2433 60.3997 22.6593 60.3997 24.3341C60.3997 25.998 59.8178 27.4139 58.6541 28.5819ZM51.2433 27.5407C52.0997 28.4001 53.1592 28.8299 54.4217 28.8299C55.6843 28.8299 56.7383 28.4001 57.5836 27.5407C58.44 26.6702 58.8682 25.6013 58.8682 24.3341C58.8682 23.0559 58.44 21.9871 57.5836 21.1276C56.7383 20.2571 55.6843 19.8219 54.4217 19.8219C53.1592 19.8219 52.0997 20.2571 51.2433 21.1276C50.398 21.9871 49.9753 23.0559 49.9753 24.3341C49.9753 25.6013 50.398 26.6702 51.2433 27.5407Z"
        fill="#404252"
      />
      <path
        d="M73.3571 24.3837V25.0779C73.3571 26.5875 72.8411 27.8437 71.809 28.8464C70.777 29.8381 69.4102 30.334 67.7084 30.334C65.9518 30.334 64.4971 29.7555 63.3443 28.5985C62.2025 27.4305 61.6316 26.009 61.6316 24.3341C61.6316 22.6593 62.2025 21.2433 63.3443 20.0863C64.4971 18.9183 65.9408 18.3343 67.6755 18.3343C68.7405 18.3343 69.7176 18.5933 70.6069 19.1112C71.4961 19.618 72.1823 20.2902 72.6654 21.1276L71.3479 21.9045C71.0186 21.2764 70.519 20.775 69.8493 20.4004C69.1906 20.0147 68.4605 19.8219 67.659 19.8219C66.3416 19.8219 65.2602 20.2571 64.4148 21.1276C63.5694 21.9871 63.1467 23.0559 63.1467 24.3341C63.1467 25.6123 63.5694 26.6812 64.4148 27.5407C65.2711 28.4001 66.3745 28.8299 67.7249 28.8299C68.8777 28.8299 69.8109 28.5489 70.5245 27.9869C71.2381 27.4139 71.6718 26.6757 71.8255 25.7721H67.5932V24.3837H73.3571Z"
        fill="#404252"
      />
      <path
        d="M75.2194 18.5492H76.751V30.1191H75.2194V18.5492Z"
        fill="#404252"
      />
      <path
        d="M92.8102 24.3838V25.0779C92.8102 26.5875 92.2941 27.8437 91.2621 28.8464C90.2301 29.8381 88.8632 30.334 87.1615 30.334C85.4049 30.334 83.9502 29.7555 82.7974 28.5985C81.6556 27.4305 81.0847 26.009 81.0847 24.3342C81.0847 22.6593 81.6556 21.2433 82.7974 20.0864C83.9502 18.9183 85.3939 18.3343 87.1286 18.3343C88.1935 18.3343 89.1707 18.5933 90.0599 19.1112C90.9492 19.6181 91.6354 20.2902 92.1185 21.1276L90.801 21.9045C90.4717 21.2764 89.9721 20.775 89.3024 20.4004C88.6437 20.0147 87.9136 19.8219 87.1121 19.8219C85.7947 19.8219 84.7132 20.2572 83.8679 21.1276C83.0225 21.9871 82.5998 23.056 82.5998 24.3342C82.5998 25.6124 83.0225 26.6812 83.8679 27.5407C84.7242 28.4002 85.8276 28.8299 87.178 28.8299C88.3308 28.8299 89.264 28.5489 89.9776 27.9869C90.6912 27.414 91.1249 26.6757 91.2786 25.7721H87.0462V24.3838H92.8102Z"
        fill="#404252"
      />
      <path
        d="M95.2489 20.3508C94.9744 20.3508 94.7438 20.2572 94.5572 20.0698C94.3706 19.8825 94.2772 19.6566 94.2772 19.3922C94.2772 19.1277 94.3706 18.9018 94.5572 18.7145C94.7438 18.5162 94.9744 18.417 95.2489 18.417C95.5124 18.417 95.7374 18.5162 95.9241 18.7145C96.1107 18.9018 96.204 19.1277 96.204 19.3922C96.204 19.6566 96.1107 19.8825 95.9241 20.0698C95.7374 20.2572 95.5124 20.3508 95.2489 20.3508ZM94.5243 30.1191V21.8549H95.957V30.1191H94.5243Z"
        fill="#404252"
      />
      <path
        d="M102.375 19.7227C100.926 19.6015 100.201 20.2296 100.201 21.607V21.8549H102.375V23.2433H100.201V30.1191H98.7686V23.2433H97.4511V21.8549H98.7686V21.607C98.7686 20.4941 99.076 19.6511 99.6908 19.0781C100.317 18.5051 101.211 18.2572 102.375 18.3343V19.7227Z"
        fill="#404252"
      />
      <path
        d="M108.251 23.2433H106.061V27.7225C106.061 28.1302 106.138 28.4222 106.291 28.5985C106.456 28.7638 106.703 28.8519 107.032 28.863C107.362 28.863 107.768 28.8519 108.251 28.8299V30.1191C106.999 30.2844 106.083 30.1852 105.501 29.8216C104.919 29.447 104.628 28.7473 104.628 27.7225V23.2433H102.998V21.8549H104.628V19.9707L106.061 19.5409V21.8549H108.251V23.2433Z"
        fill="#404252"
      />
      <path
        d="M110.78 24.0862C110.78 24.4058 110.939 24.6647 111.258 24.8631C111.576 25.0504 111.961 25.2047 112.411 25.3259C112.861 25.4361 113.311 25.5683 113.761 25.7226C114.211 25.8658 114.595 26.1192 114.914 26.4829C115.232 26.8355 115.391 27.2928 115.391 27.8547C115.391 28.604 115.1 29.2045 114.519 29.6563C113.948 30.1081 113.212 30.334 112.312 30.334C111.51 30.334 110.824 30.1577 110.253 29.8051C109.682 29.4525 109.276 28.9842 109.035 28.4002L110.27 27.6894C110.402 28.0861 110.649 28.4002 111.011 28.6316C111.373 28.863 111.807 28.9787 112.312 28.9787C112.784 28.9787 113.174 28.8905 113.481 28.7142C113.789 28.5269 113.942 28.2404 113.942 27.8547C113.942 27.5352 113.783 27.2817 113.465 27.0944C113.146 26.8961 112.762 26.7418 112.312 26.6316C111.862 26.5104 111.412 26.3727 110.961 26.2184C110.511 26.0641 110.127 25.8107 109.809 25.4581C109.49 25.1055 109.331 24.6537 109.331 24.1028C109.331 23.3865 109.606 22.797 110.154 22.3342C110.714 21.8714 111.412 21.64 112.246 21.64C112.916 21.64 113.509 21.7943 114.025 22.1028C114.552 22.4003 114.947 22.8191 115.21 23.359L114.008 24.0367C113.712 23.3314 113.124 22.9788 112.246 22.9788C111.84 22.9788 111.494 23.078 111.208 23.2763C110.923 23.4637 110.78 23.7336 110.78 24.0862Z"
        fill="#404252"
      />
    </SvgIcon>
  )
}

export default LogoWithText
