import theme from 'config/theme'

export const getStyles = () => {
  return {
    alertContainer: {
      display: 'block',
      position: 'absolute',
      bottom: '3.5rem',
      right: '5.8rem',
      maxWidth: '53.6rem',
      padding: '2.7rem 3.3rem',
      backgroundColor: '#FFCBC9',
      border: `.1rem solid ${theme.palette.notifications.warningBorder}`,
      fontSize: '1.8rem',
      fontWeight: 600,
      color: theme.palette.primary.dark,

      svg: {
        position: 'absolute',
        top: '1.2rem',
        right: '1.2rem',
        fontSize: '2rem',
        cursor: 'pointer'
      }
    }
  }
}
