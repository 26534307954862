const getErrorMessage = ({
  error,
  defaultMessage = 'Something went wrong'
}) => {
  return error?.response?.data?.detail?.length ? (
    <>
      {Array.isArray(error.response.data.detail) ? (
        error.response.data.detail.map(err => (
          <p key={error?.response?.status}>{err.msg}</p>
        ))
      ) : (
        <p key={error.response.status}>{error.response.data.detail}</p>
      )}
    </>
  ) : (
    defaultMessage
  )
}

export default getErrorMessage
