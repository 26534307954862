import { makeAutoObservable } from 'mobx'

class HeaderStore {
  titleDetails = ''

  constructor() {
    makeAutoObservable(this, {})
  }

  setTitle = newTitleDetails => {
    this.titleDetails = newTitleDetails
  }

  clearTitle = () => {
    this.titleDetails = ''
  }
}

export default new HeaderStore()
