import { SvgIcon } from '@mui/material'

const ShopIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 20" {...props}>
      <path d="M18.0189 7.10137L17.7944 4.9569C17.4693 2.61889 16.4086 1.66666 14.1403 1.66666H12.3132H11.1675H8.81397H7.66819H5.81017C3.53409 1.66666 2.48121 2.61889 2.14831 4.98013L1.93929 7.10911C1.86187 7.93748 2.08638 8.74262 2.57411 9.3697C3.16248 10.1361 4.06827 10.5697 5.0747 10.5697C6.05016 10.5697 6.98691 10.0819 7.57529 9.30003C8.10173 10.0819 8.99977 10.5697 9.99846 10.5697C10.9971 10.5697 11.872 10.1052 12.4061 9.331C13.0023 10.0974 13.9235 10.5697 14.8835 10.5697C15.9132 10.5697 16.8422 10.1129 17.4228 9.30777C17.8873 8.68843 18.0963 7.90651 18.0189 7.10137Z" />
      <path d="M9.49546 13.5967C8.51225 13.6974 7.76904 14.5335 7.76904 15.5244V17.6457C7.76904 17.8547 7.93936 18.025 8.14839 18.025H11.8412C12.0502 18.025 12.2206 17.8547 12.2206 17.6457V15.7954C12.2283 14.1773 11.2761 13.4109 9.49546 13.5967Z" />
      <path d="M17.2527 11.8471V14.1541C17.2527 16.2908 15.5185 18.025 13.3818 18.025C13.1728 18.025 13.0025 17.8547 13.0025 17.6457V15.7954C13.0025 14.8044 12.7005 14.0303 12.1122 13.5038C11.5935 13.0316 10.889 12.7993 10.0141 12.7993C9.8206 12.7993 9.62705 12.8071 9.41802 12.8303C8.03999 12.9696 6.99485 14.1309 6.99485 15.5244V17.6457C6.99485 17.8547 6.82454 18.025 6.61551 18.025C4.47878 18.025 2.74463 16.2908 2.74463 14.1541V11.8626C2.74463 11.3206 3.27881 10.9568 3.78202 11.1348C3.99105 11.2045 4.20008 11.2587 4.41685 11.2897C4.50975 11.3052 4.61039 11.3206 4.70329 11.3206C4.82716 11.3361 4.95103 11.3439 5.0749 11.3439C5.97294 11.3439 6.8555 11.011 7.55226 10.4381C8.21805 11.011 9.08513 11.3439 9.99866 11.3439C10.9199 11.3439 11.7715 11.0265 12.4373 10.4536C13.1341 11.0187 14.0011 11.3439 14.8837 11.3439C15.0231 11.3439 15.1624 11.3361 15.294 11.3206C15.3869 11.3129 15.4721 11.3052 15.5572 11.2897C15.7972 11.2587 16.014 11.189 16.2308 11.1194C16.734 10.949 17.2527 11.3206 17.2527 11.8471Z" />
    </SvgIcon>
  )
}

export default ShopIcon
