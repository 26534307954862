import { SvgIcon } from '@mui/material'

const ArrowLeftIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none !important', ...sx }}
      viewBox="0 0 8 14"
      {...props}
    >
      <path
        d="M7 13L1 7L7 1"
        stroke="#282A3A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default ArrowLeftIcon
