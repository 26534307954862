import { SvgIcon } from '@mui/material'

const MailIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 20" {...props}>
      <path d="M17.7501 10.1917H14.8501C14.0334 10.1917 13.3084 10.6417 12.9417 11.375L12.2417 12.7583C12.0751 13.0917 11.7417 13.3 11.3751 13.3H8.64175C8.38341 13.3 8.01675 13.2417 7.77508 12.7583L7.07508 11.3833C6.70841 10.6583 5.97508 10.2 5.16675 10.2H2.25008C1.92508 10.2 1.66675 10.4583 1.66675 10.7833V13.5C1.66675 16.525 3.48341 18.3333 6.51675 18.3333H13.5001C16.3584 18.3333 18.1167 16.7667 18.3334 13.9833V10.775C18.3334 10.4583 18.0751 10.1917 17.7501 10.1917Z" />
      <path d="M10.625 3.17501L11.225 3.77501C11.35 3.90001 11.5083 3.95834 11.6667 3.95834C11.825 3.95834 11.9833 3.90001 12.1083 3.77501C12.35 3.53334 12.35 3.13334 12.1083 2.89167L10.4417 1.22501C10.4333 1.21667 10.425 1.21667 10.425 1.20834C10.375 1.15834 10.3083 1.11667 10.2417 1.08334C10.2333 1.08334 10.2333 1.08334 10.225 1.07501C10.1583 1.05001 10.0917 1.04167 10.025 1.03334C10 1.03334 9.98333 1.03334 9.95833 1.03334C9.90833 1.03334 9.85833 1.05001 9.80833 1.06667C9.78333 1.07501 9.76667 1.08334 9.75 1.09167C9.68333 1.12501 9.61667 1.15834 9.56667 1.21667L7.9 2.88334C7.65833 3.12501 7.65833 3.52501 7.9 3.76667C8.14167 4.00834 8.54167 4.00834 8.78333 3.76667L9.38333 3.16667V4.16667H10.6333V3.17501H10.625Z" />
      <path d="M18.3334 9.00834V9.04167C18.1501 8.97501 17.9501 8.94167 17.7501 8.94167H14.8501C13.5584 8.94167 12.4001 9.65834 11.8251 10.8083L11.2001 12.0417H8.81675L8.19175 10.8167C7.61675 9.65834 6.45841 8.94167 5.16675 8.94167H2.25008C2.05008 8.94167 1.85008 8.97501 1.66675 9.04167V9.00834C1.66675 5.97501 3.47508 4.16667 6.50841 4.16667H9.37508V7.50001C9.37508 7.84167 9.65841 8.12501 10.0001 8.12501C10.3417 8.12501 10.6251 7.84167 10.6251 7.50001V4.16667H13.4917C16.5251 4.16667 18.3334 5.97501 18.3334 9.00834Z" />
    </SvgIcon>
  )
}

export default MailIcon
