import { SvgIcon } from '@mui/material'

const FileFolderIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 20" {...props}>
      <path d="M18 6.92058V5.99201C18 5.47915 17.5522 5.06344 17 5.06344H9.8285C9.29797 5.06344 8.78931 4.8678 8.41419 4.51947L7.58581 3.75024C7.21069 3.40194 6.70203 3.2063 6.1715 3.2063H3C2.44775 3.2063 2 3.62201 2 4.13487V6.92058H18Z" />
      <path d="M2 8.2063V15.6349C2 16.1477 2.44775 16.5634 3 16.5634H17C17.5522 16.5634 18 16.1477 18 15.6349V8.2063H2Z" />
    </SvgIcon>
  )
}

export default FileFolderIcon
