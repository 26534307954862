import { useState, useEffect } from 'react'
import { Box } from '@mui/material'

import CrossIcon from 'assets/svg/CrossIcon'

import { getStyles } from './styles'

const Alert = ({ message, initialIsOpen }) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(initialIsOpen)
  }, [initialIsOpen])

  const classes = getStyles()

  return (
    isOpen && (
      <Box sx={classes.alertContainer}>
        {message} <CrossIcon onClick={() => setIsOpen(false)} />
      </Box>
    )
  )
}

export default Alert
