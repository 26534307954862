import { makeObservable, observable, action } from 'mobx'

class SortingStore {
  sortParams = undefined

  constructor() {
    makeObservable(this, {
      sortParams: observable.ref,

      cleanSortParams: action,
      setSortParams: action
    })
  }

  cleanSortParams = () => {
    this.sortParams = undefined
  }

  setSortParams = params => {
    this.sortParams = params
  }
}

export default new SortingStore()
