import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import { Box, Typography } from '@mui/material'

import authStore from 'pages/LoginPage/store'
import filtersStore from 'stores/filtersStore'
import sortingStore from 'stores/sortingStore'

import { menuRoutes, avatars } from './arrays'
import { privatePaths, publicPaths } from 'config/routes'

import Alert from 'components/Alert'
import Title from './Title'
import MenuItem from './MenuItem'
import Loading from 'components/Loading'
import LogoWithText from 'assets/svg/LogoWithText'
import LogoutIcon from 'assets/svg/LogoutIcon'
import DropdownArrowIcon from 'assets/svg/DropdownArrowIcon'

import { getStyles } from './styles'

const Layout = ({ children }) => {
  const role = localStorage.getItem('role')
  const location = useLocation()
  const params = useParams()
  const [active, setActive] = useState(
    menuRoutes()[role].find(item => location.pathname.includes(item.path))
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const { getUser, user, logout, isLoadingUser } = authStore
  const { cleanFilters } = filtersStore
  const { cleanSortParams } = sortingStore


  useEffect(() => {
    if (!isLoadingUser && role !== 'superuser' && role !== 'member' && role !== 'shopper' && role !== 'ogmember') {
      getUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (params.merchantId) {
      setActive(
        menuRoutes()[role].find(item => location.pathname.includes(item.path))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])
  useEffect(() => {
    if (
      location.pathname ===
      privatePaths[role][Object.keys(privatePaths[role])[0]]
    ) {
      setActive(menuRoutes()[role][0])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const classes = getStyles()
  const navigate = useNavigate()

  const handleMenuItemClick = route => {
    route.cleanFilters && cleanFilters()
    cleanSortParams()
    setActive(route)
    navigate(route.path, { replace: true })
  }

  const handleOpenUserInfo = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const isActive = route => {
    if (route.path === '/franchisee/merchant/allMerchants') {
      return location.pathname.includes(route.path.replace('/allMerchants', ''))
    }
    if (route.path === '/franchisee/all_gifts/allGifts') {
      return location.pathname.includes(route.path.replace('/allGifts', ''))
    }
    if (route.path === '/franchisee/push_notifications/notification_form') {
      return location.pathname.includes(
        route.path.replace('/notification_form', '')
      )
    }
    if (route.path === '/superuser/merchants/allMerchants') {
      return location.pathname.includes(
        route.path.replace('/allMerchants', '')
      )
    }
    if (route.path === '/superuser/wallet/rechargeSuperuserWallet') {
      return location.pathname.includes(
        route.path.replace('/rechargeSuperuserWallet', '')
      )
    }
    if (route.path === '/franchisee/mass_email/mass_email_form') {
      return location.pathname.includes(
        route.path.replace('/mass_email_form', '')
      )
    }
    if (route.path === '/franchisee/translations/login_page') {
      return location.pathname.includes(
        route.path.replace('/login_page', '')
      )
    }
    if (route.path === '/franchisee/wallet/withdrawalRequests') {
      return location.pathname.includes(
        route.path.replace('/withdrawalRequests', '')
      )
    }
    if (route.path === '/sub_franchisee/merchant/allMerchants') {
      return location.pathname.includes(route.path.replace('/allMerchants', ''))
    }
    if (route.path === '/sub_franchisee/gifts/allGifts') {
      return location.pathname.includes(route.path.replace('/allGifts', ''))
    }
    if (route.path === '/sub_franchisee/push_notifications/notification_form') {
      return location.pathname.includes(
        route.path.replace('/notification_form', '')
      )
    }
    if (route.path === '/merchant/file_folder/allPhotos') {
      return location.pathname.includes(route.path.replace('/allPhotos', ''))
    }
    if (route.path === '/merchant/wallet/details') {
      return location.pathname.includes(route.path.replace('/details', ''))
    }
    if (route.path === '/merchant/app_settings/users') {
      return location.pathname.includes(route.path.replace('/users', ''))
    }
    if (route.path === '/merchant/my_gifts/allGifts') {
      return location.pathname.includes(route.path.replace('/allGifts', ''))
    }

    return location.pathname.includes(route.path)
  }

  const userInfoItems = [
    {
      name: 'country'.toUpperCase(),
      value: user?.country || 'unknown'.toUpperCase()
    },
    {
      name: 'currency'.toUpperCase(),
      value: `${user?.currency?.letter_code || 'unknown'.toUpperCase()} ${
        user?.currency?.symbol || ''
      }`
    },
    { name: 'language'.toUpperCase(), value: 'english'.toUpperCase() }
  ]

  const userType = role === 'franchisee' || role === 'subfranchisee' || role === 'merchant'
  ? user?.type?.replace(/\s/g, '')
  : role === 'member' || role === 'shopper'
  ? role.charAt(0).toUpperCase() + role.slice(1)
  : role === 'ogmember'
  ? role.substring(0, 2).toUpperCase() + role.substring(2)
  : 'Admin'

  if (isLoadingUser) return <Loading />
  return (
    <Box sx={classes.container}>
      <Box sx={classes.menu}>
        <Box sx={classes.sideBarContent}>
          <LogoWithText sx={classes.sideBarLogo} />
          {menuRoutes(
            Boolean(user?.type === 'Merchant' && user.status === 'Unverified')
          )[role].map(route => (
            <MenuItem
              title={route?.title}
              icon={route?.icon}
              disabled={route?.disabled}
              onClick={() => handleMenuItemClick(route)}
              active={isActive(route)}
              key={route?.id}
            />
          ))}
        </Box>
        <Box sx={classes.logout} onClick={() => logout({ navigate: location.pathname.includes(privatePaths.shopper.profile) | location.pathname.includes(privatePaths.member.profile)?navigate(publicPaths.client_login):'' })}>
          <LogoutIcon />
          <Typography variant="h6">Logout</Typography>
        </Box>
      </Box>
      <Box sx={classes.headerWithContent}>
        <Box sx={classes.header}>
          <Title
            classes={classes}
            active={active}
            params={params}
            navigate={navigate}
          />
          <Box sx={classes.userInfo}>
            {avatars[role]}
            <Typography variant="h6">
              {userType}
            </Typography>

            {user?.type?.replace(/\s/g, '') && (
              <DropdownArrowIcon
                onClick={handleOpenUserInfo}
                sx={
                  anchorEl
                    ? classes.dropdownIconClosed
                    : classes.dropdownIconOpen
                }
              />
            )}
          </Box>
          <Menu
            sx={classes.userMenuWrapper}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            elevation={0}
          >
            {userInfoItems.map((el, index) => (
              <Box key={index}>
                <Typography>{el.name}</Typography>
                <Typography>{el.value}</Typography>
              </Box>
            ))}
          </Menu>
        </Box>
        <Box sx={classes.content}>{children}</Box>
      </Box>
      <Alert
        initialIsOpen={Boolean(
          user?.type === 'Merchant' && user.status === 'Unverified'
        )}
        message="*Your Gifts and Promotions cannot become active if your account has not been approved"
      />
    </Box>
  )
}

export default observer(Layout)
