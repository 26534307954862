import { SvgIcon } from '@mui/material'

const BusinessInfoIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 20" {...props}>
      <path d="M18.0204 7.30758L17.7959 5.16311C17.4707 2.8251 16.4101 1.87286 14.1418 1.87286H12.3147H11.1689H8.81543H7.66965H5.81163C3.53555 1.87286 2.48267 2.8251 2.14978 5.18634L1.94075 7.31532C1.86333 8.14369 2.08785 8.94883 2.57558 9.57591C3.16395 10.3423 4.06974 10.7759 5.07616 10.7759C6.05162 10.7759 6.98838 10.2882 7.57675 9.50624C8.10319 10.2882 9.00123 10.7759 9.99992 10.7759C10.9986 10.7759 11.8734 10.3114 12.4076 9.5372C13.0037 10.3036 13.925 10.7759 14.885 10.7759C15.9146 10.7759 16.8436 10.3191 17.4243 9.51398C17.8888 8.89464 18.0978 8.11272 18.0204 7.30758Z" />

      <path d="M9.49594 13.8029C8.51274 13.9036 7.76953 14.7397 7.76953 15.7306V17.8519C7.76953 18.0609 7.93985 18.2312 8.14888 18.2312H11.8417C12.0507 18.2312 12.221 18.0609 12.221 17.8519V16.0016C12.2288 14.3836 11.2765 13.6171 9.49594 13.8029Z" />

      <path d="M17.2541 12.0533V14.3603C17.2541 16.4971 15.52 18.2312 13.3833 18.2312C13.1742 18.2312 13.0039 18.0609 13.0039 17.8519V16.0016C13.0039 15.0106 12.702 14.2365 12.1136 13.71C11.5949 13.2378 10.8904 13.0055 10.0156 13.0055C9.82206 13.0055 9.62852 13.0133 9.41949 13.0365C8.04146 13.1758 6.99632 14.3371 6.99632 15.7306V17.8519C6.99632 18.0609 6.826 18.2312 6.61697 18.2312C4.48025 18.2312 2.74609 16.4971 2.74609 14.3603V12.0688C2.74609 11.5268 3.28027 11.163 3.78349 11.341C3.99252 11.4107 4.20154 11.4649 4.41831 11.4959C4.51121 11.5114 4.61186 11.5268 4.70476 11.5268C4.82863 11.5423 4.95249 11.5501 5.07636 11.5501C5.97441 11.5501 6.85697 11.2172 7.55373 10.6443C8.21952 11.2172 9.08659 11.5501 10.0001 11.5501C10.9214 11.5501 11.773 11.2327 12.4388 10.6598C13.1355 11.2249 14.0026 11.5501 14.8852 11.5501C15.0245 11.5501 15.1639 11.5423 15.2955 11.5268C15.3884 11.5191 15.4735 11.5114 15.5587 11.4959C15.7987 11.4649 16.0155 11.3952 16.2322 11.3256C16.7354 11.1552 17.2541 11.5268 17.2541 12.0533Z" />
      <path d="M18.0204 7.30758L17.7959 5.16311C17.4707 2.8251 16.4101 1.87286 14.1418 1.87286H12.3147H11.1689H8.81543H7.66965H5.81163C3.53555 1.87286 2.48267 2.8251 2.14978 5.18634L1.94075 7.31532C1.86333 8.14369 2.08785 8.94883 2.57558 9.57591C3.16395 10.3423 4.06974 10.7759 5.07616 10.7759C6.05162 10.7759 6.98838 10.2882 7.57675 9.50624C8.10319 10.2882 9.00123 10.7759 9.99992 10.7759C10.9986 10.7759 11.8734 10.3114 12.4076 9.5372C13.0037 10.3036 13.925 10.7759 14.885 10.7759C15.9146 10.7759 16.8436 10.3191 17.4243 9.51398C17.8888 8.89464 18.0978 8.11272 18.0204 7.30758Z" />

      <path d="M9.49594 13.8029C8.51274 13.9036 7.76953 14.7397 7.76953 15.7306V17.8519C7.76953 18.0609 7.93985 18.2312 8.14888 18.2312H11.8417C12.0507 18.2312 12.221 18.0609 12.221 17.8519V16.0016C12.2288 14.3836 11.2765 13.6171 9.49594 13.8029Z" />

      <path d="M17.2541 12.0533V14.3603C17.2541 16.4971 15.52 18.2312 13.3833 18.2312C13.1742 18.2312 13.0039 18.0609 13.0039 17.8519V16.0016C13.0039 15.0106 12.702 14.2365 12.1136 13.71C11.5949 13.2378 10.8904 13.0055 10.0156 13.0055C9.82206 13.0055 9.62852 13.0133 9.41949 13.0365C8.04146 13.1758 6.99632 14.3371 6.99632 15.7306V17.8519C6.99632 18.0609 6.826 18.2312 6.61697 18.2312C4.48025 18.2312 2.74609 16.4971 2.74609 14.3603V12.0688C2.74609 11.5268 3.28027 11.163 3.78349 11.341C3.99252 11.4107 4.20154 11.4649 4.41831 11.4959C4.51121 11.5114 4.61186 11.5268 4.70476 11.5268C4.82863 11.5423 4.95249 11.5501 5.07636 11.5501C5.97441 11.5501 6.85697 11.2172 7.55373 10.6443C8.21952 11.2172 9.08659 11.5501 10.0001 11.5501C10.9214 11.5501 11.773 11.2327 12.4388 10.6598C13.1355 11.2249 14.0026 11.5501 14.8852 11.5501C15.0245 11.5501 15.1639 11.5423 15.2955 11.5268C15.3884 11.5191 15.4735 11.5114 15.5587 11.4959C15.7987 11.4649 16.0155 11.3952 16.2322 11.3256C16.7354 11.1552 17.2541 11.5268 17.2541 12.0533Z" />
    </SvgIcon>
  )
}

export default BusinessInfoIcon
