import { makeObservable, observable, action, runInAction } from 'mobx'
import axios, { publicAxios } from 'config/axios'
import { privatePaths } from 'config/routes'
import toast from 'react-hot-toast'
import getErrorMessage from 'utils/getErrorMessage'

class AuthStore {
  user = {}
  isLoadingLogin = false
  isLoadingUser = false

  constructor() {
    makeObservable(this, {
      user: observable.ref,
      isLoadingLogin: observable.ref,
      isLoadingUser: observable.ref,
      login: action,
      getUser: action,
      logout: action,
    })
  }

  //NOTE:http://65.108.62.222/redoc#tag/auth/operation/login_backoffice_user_access_token_api_v1_back_auth_access_token_post
  login = ({ payload, navigate }) => {
    runInAction(() => {
      this.isLoadingLogin = true
    })

    publicAxios
      .post('/auth/access-token', payload)
      .then(({ data }) => {
        localStorage.setItem('access_token', data.access_token)
        localStorage.setItem(
          'role',
          data.user_type.replace(/\s/g, '').toLowerCase()
        )
        localStorage.setItem('refresh_token', data.refresh_token)
        navigate &&
          navigate(
            privatePaths[data.user_type.replace(/\s/g, '').toLowerCase()][
              Object.keys(
                privatePaths[data.user_type.replace(/\s/g, '').toLowerCase()]
              )[0]
            ],
            {
              replace: true
            }
          )
        window.location.reload()

        runInAction(() => {
          this.isLoadingLogin = false
        })
      })
      .catch(error => {
        toast.error(
          getErrorMessage({ error, defaultMessage: 'Login was failed' })
        )
        runInAction(() => {
          this.isLoadingLogin = false
        })
      })
  }

  //NOTE:
  //franchisee:http://65.108.62.222/redoc#tag/franchisee/operation/get_franchisee_me_api_v1_back_fran_me_get
  //subfranshisee:http://65.108.62.222/redoc#tag/sub_franchisee/operation/get_sub_franchisee_me_api_v1_back_sub_fran_me_get
  //merchant:http://65.108.62.222/redoc#tag/merchant/operation/get_merchant_me_api_v1_back_merchant_me_get
  getUser = () => {
    runInAction(() => {
      this.isLoadingUser = true
    })
    const role = localStorage.getItem('role')
    const formattedRole =
      role === 'franchisee'
        ? 'fran'
        : role === 'subfranchisee'
        ? 'sub_fran'
        : role
    axios
      .get(`/${formattedRole}/me`)
      .then(({ data }) => {
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...data.user,
            country: data.country,
            currency: data.currency,
            status: data.status,
            coordinates: data.coordinates,
          })
        )
        runInAction(() => {
          this.user = {
            ...data.user,
            country: data.country,
            currency: data.currency,
            status: data.status,
            coordinates: data.coordinates,
          }
          this.isLoadingUser = false
        })
      })
      .catch(error => {
        toast.error(
          getErrorMessage({
            error,
            defaultMessage: 'Fetch user data was failed'
          })
        )
        runInAction(() => {
          this.isLoadingUser = false
        })
      })
  }

  logout = () => {
    runInAction(() => {
      localStorage.clear()
      this.user = {}
    })
  }
}

export default new AuthStore()
