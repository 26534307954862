import { SvgIcon } from '@mui/material'

const WalletIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ ...sx }}
      viewBox="0 0 20 21"
      width="20"
      height="21"
      {...props}
    >
      <path d="M13.7484 12.7873C12.9817 12.7873 12.3579 12.1547 12.3579 11.377V9.03503C12.3579 8.25742 12.9817 7.62481 13.7484 7.62481H17.3715C17.3934 7.62481 17.4152 7.62548 17.4369 7.62647V5.29344C17.4369 4.69289 16.9569 4.20605 16.3648 4.20605H3.07215C2.48 4.20602 2 4.69286 2 5.2934V15.1186C2 15.7192 2.48 16.2061 3.07215 16.2061H16.3648C16.9569 16.2061 17.4369 15.7192 17.4369 15.1186V12.7856C17.4152 12.7866 17.3934 12.7873 17.3715 12.7873H13.7484Z" />

      <path d="M17.3727 8.39746H13.7496C13.4025 8.39746 13.1211 8.68289 13.1211 9.03496V11.377C13.1211 11.729 13.4025 12.0145 13.7496 12.0145H17.3727C17.7198 12.0145 18.0012 11.7291 18.0012 11.377V9.03496C18.0012 8.68285 17.7198 8.39746 17.3727 8.39746ZM14.9981 11.1261C14.497 11.1261 14.0909 10.7141 14.0909 10.2059C14.0909 9.69775 14.497 9.28581 14.9981 9.28581C15.4991 9.28581 15.9053 9.69775 15.9053 10.2059C15.9053 10.7141 15.4991 11.1261 14.9981 11.1261Z" />
    </SvgIcon>
  )
}

export default WalletIcon
