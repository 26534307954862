import { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { publicPaths, privatePaths } from 'config/routes'
import { observer } from 'mobx-react-lite'

import authStore from 'pages/LoginPage/store'

import PrivateRoute from 'core/PrivateRoute'
import PublicRoute from 'core/PublicRoute'
import LinearProgress from '@mui/material/LinearProgress'

const SuperuserFranchisee = lazy(() => import('pages/Superuser/Franchisee'))
const SuperuserMerchantsTabsPage = lazy(() =>
  import('pages/Superuser/Merchants')
)
const SuperuserContactManager = lazy(() =>
  import('pages/Superuser/ContactManager')
)
const SuperuserWallet = lazy(() => import('pages/Superuser/Wallet'))
const SuperuserSales = lazy(() => import('pages/Superuser/Sales'))
const SuperuserGifts = lazy(() => import('pages/Superuser/Gifts'))
const SuperuserGiftDetails = lazy(() => import('pages/Superuser/Gifts/components/GiftDetails'))
const SuperuserFinancials = lazy(() => import('pages/Superuser/Financials'))
const SuperuserFinancialsTabsPage = lazy(() =>
  import('pages/Superuser/Financials/components')
)
const ContactManagerTabsPage = lazy(() =>
  import('pages/Superuser/ContactManager/components/DetailsPage')
)
const SuperuserFranchiseeCreate = lazy(() =>
  import('pages/Superuser/FranchiseeCreate')
)
const FranchiseeTabsPage = lazy(() =>
  import('pages/Superuser/Franchisee/components/DetailsPage')
)
const SuperuserAppSettings = lazy(() =>
  import('pages/Superuser/AppSettings')
)
const SubfranchiseeDetailPage = lazy(() =>
  import(
    'commonComponents/FranchiseeDetailsPage/SubFranchisee/SubfranchiseeDetail'
  )
)
const MerchantDetail = lazy(() =>
  import('commonComponents/MerchantDetailsPage')
)
const ContactManagerMerchantDetail = lazy(() =>
  import('commonComponents/MerchantDetailsPage')
)
const ContactManagerSubFranchiseeDetail = lazy(() =>
  import(
    'commonComponents/FranchiseeDetailsPage/SubFranchisee/SubfranchiseeDetail'
  )
)
const LoginPage = lazy(() => import('pages/LoginPage'))
const VerifyPage = lazy(() => import('pages/VerifyPage'))
const ResetPasswordPage = lazy(() => import('pages/ResetPasswordPage'))
const SubFranchiseesPage = lazy(() =>
  import('pages/Franchisee/SubFranshiseesPage')
)
const SubFranchiseesCreatePage = lazy(() =>
  import('pages/Franchisee/SubFranshiseeCreate')
)
const NotExistingPage = lazy(() => import('pages/NotExistingPage'))
const ComingSoonPage = lazy(() =>
  import('pages/NotExistingPage/ComingSoonPage')
)
const FranchiseeAppSettingsPage = lazy(() =>
  import('pages/Franchisee/AppSettingsPage')
)
const ProfilePage = lazy(() => import('pages/Profile/ProfilePage'))
const ClientProfilePage = lazy(() => import('pages/Profile/ClientProfilePage'))
const SubFranshiseeDetailsPage = lazy(() =>
  import('pages/Franchisee/SubFranshiseeDetailsPage')
)
const WalletPage = lazy(() => import('pages/Franchisee/Wallet'))
const SubFranchiseeMerchantPage = lazy(() =>
  import('pages/SubFranshisee/MerchantPage')
)
const MerchantPage = lazy(() => import('pages/Franchisee/MerchantPage'))
const ShoppersMembersPage = lazy(() =>
  import('pages/Franchisee/ShoppersMembersPage')
)
const MerchantGiftDetails = lazy(() =>
  import(
    'pages/Franchisee/components/MerchantPage/MerchantDetails/MerchantGifts/MerchantGiftDetails'
  )
)
const MerchantDetails = lazy(() =>
  import('commonComponents/MerchantDetailsPage')
)
const MassEmailPage = lazy(() => import('pages/Franchisee/MassEmailPage'))
const MassEmailDetails = lazy(() =>
  import('pages/Franchisee/components/MassEmailPage/MassEmailDetails')
)
const PushNotificationsPage = lazy(() =>
  import('pages/Franchisee/PushNotificationsPage')
)
const AllGiftsPage = lazy(() =>
  import('pages/Franchisee/AllGifts/AllGiftsPage')
)
const NotificationsData = lazy(() =>
  import('pages/Franchisee/components/PushNotificationsPage/NotificationsData')
)
const FileFolderPage = lazy(() => import('pages/Merchant/FileFolderPage'))
const AllSubFranshiseeGiftsPage = lazy(() =>
  import('pages/SubFranshisee/Gifts')
)
const SubFranshiseeGiftDetailsPage = lazy(() =>
  import('pages/SubFranshisee/Gifts/SubFranshiseeGiftDetailPage')
)

const FranchiseeMerchantDetails = lazy(() =>
  import('pages/Franchisee/components/MerchantPage/MerchantDetails')
)

const SuperuserMerchantDetails = lazy(() =>
  import('commonComponents/MerchantDetailsPage')
)

const FranchiseeTranslationsDetailsPage = lazy(() =>
  import('pages/Franchisee/TranslationsPage')
)

const FranchiseeSubFranMerchantDetails = lazy(() =>
  import('commonComponents/MerchantDetailsPage')
)

const MerchantWalletPage = lazy(() => import('pages/Merchant/WalletPage'))

const BusinessInfoPage = lazy(() => import('pages/Merchant/BusinessInfoPage'))

const AppSettingsPage = lazy(() => import('pages/Merchant/AppSettingsPage'))

const GiftClaimPage = lazy(() => import('pages/Merchant/GiftClaimPage'))

const GiftsPage = lazy(() => import('pages/Merchant/GiftsPage'))

const CreatePromoPage = lazy(() => import('pages/Merchant/CreatePromoPage'))

const CreateGiftProfile = lazy(() => import('pages/Merchant/CreateGiftProfile'))

const GiftDetailsPage = lazy(() => import('pages/Merchant/GiftDetailsPage'))

const MerchantProfilePage = lazy(() => import('pages/Merchant/ProfilePage'))

const PromotionDetailsPage = lazy(() =>
  import('commonComponents/PromotionDetailsPage')
)
const SubFranchiseeMapPage = lazy(() =>
  import('pages/Franchisee/SubFranchiseeMapPage')
)

const publicRoutes = [
  { path: publicPaths.login, Component: <LoginPage /> },
  { path: publicPaths.client_login, Component: <LoginPage /> },
  { path: publicPaths.verify, Component: <VerifyPage /> },
  { path: publicPaths.reset_password, Component: <ResetPasswordPage /> }
]

const privateRoutes = {
  franchisee: [
    {
      path: privatePaths.franchisee.sub_franchisees,
      Component: <SubFranchiseesPage />
    },
    {
      path: privatePaths.franchisee.sub_franchiseesCreate,
      Component: <SubFranchiseesCreatePage />
    },
    {
      path: privatePaths.franchisee.subFranchiseeMap,
      Component: <SubFranchiseeMapPage />
    },
    {
      path: `${privatePaths.franchisee.subFranchisee}/*`,
      Component: <SubFranshiseeDetailsPage />
    },
    {
      path: `${privatePaths.franchisee.merchant}/*`,
      Component: <MerchantPage />
    },
    {
      path: `${privatePaths.franchisee.allGifts}/*`,
      Component: <AllGiftsPage />
    },
    {
      path: `${privatePaths.franchisee.giftsMerchantDetails}/*`,
      Component: <FranchiseeMerchantDetails />
    },
    {
      path: `${privatePaths.franchisee.translations}/*`,
      Component: <FranchiseeTranslationsDetailsPage />
    },
    {
      path: `${privatePaths.franchisee.giftsGiftDetails}/*`,
      Component: <MerchantGiftDetails />
    },
    {
      path: `${privatePaths.franchisee.promotionDetails}`,
      Component: <PromotionDetailsPage />
    },
    {
      path: privatePaths.franchisee.shoppersMembers,
      Component: <ShoppersMembersPage />
    },
    {
      path: `${privatePaths.franchisee.merchantDetails}/*`,
      Component: <FranchiseeMerchantDetails />
    },
    {
      path: privatePaths.franchisee.subFranMerchantDetails,
      Component: <FranchiseeSubFranMerchantDetails />
    },
    {
      path: privatePaths.franchisee.merchantGiftDetails,
      Component: <MerchantGiftDetails />
    },
    {
      path: `${privatePaths.franchisee.massEmail}/*`,
      Component: <MassEmailPage />
    },
    {
      path: privatePaths.franchisee.massEmailDetails,
      Component: <MassEmailDetails />
    },
    {
      path: `${privatePaths.franchisee.pushNotifications}/*`,
      Component: <PushNotificationsPage />
    },
    {
      path: privatePaths.franchisee.pushNotificationsData,
      Component: <NotificationsData />
    },
    {
      path: `${privatePaths.franchisee.wallet}/*`,
      Component: <WalletPage />
    },
    {
      path: '*',
      Component: <NotExistingPage />
    },
    {
      path: `${privatePaths.franchisee.appSettings}/*`,
      Component: <FranchiseeAppSettingsPage />
    },
    {
      path: `${privatePaths.franchisee.profile}`,
      Component: <ProfilePage />
    }
  ],
  subfranchisee: [
    {
      path: `${privatePaths.subfranchisee.merchant}/*`,
      Component: <SubFranchiseeMerchantPage adminType="sub_fran" />
    },
    {
      path: `${privatePaths.subfranchisee.merchantDetails}/*`,
      Component: <MerchantDetails adminType="subfranch" />
    },
    {
      path: `${privatePaths.subfranchisee.merchantGiftDetails}`,
      Component: <SubFranshiseeGiftDetailsPage />
    },
    {
      path: `${privatePaths.subfranchisee.gifts}/*`,
      Component: <AllSubFranshiseeGiftsPage />
    },
    {
      path: `${privatePaths.subfranchisee.merchantGiftDetails}/*`,
      Component: <SubFranshiseeGiftDetailsPage />
    },
    {
      path: `${privatePaths.subfranchisee.giftDetails}/*`,
      Component: <SubFranshiseeGiftDetailsPage />
    },
    {
      path: `${privatePaths.subfranchisee.promotionDetails}`,
      Component: <PromotionDetailsPage />
    },
    {
      path: `${privatePaths.subfranchisee.profile}`,
      Component: <ProfilePage />
    },
    {
      path: '*',
      Component: <NotExistingPage />
    }
  ],
  superuser: [
    {
      path: privatePaths.superuser.franchisee,
      Component: <SuperuserFranchisee />
    },
    {
      path: `${privatePaths.superuser.merchants}/*`,
      Component: <SuperuserMerchantsTabsPage />
    },
    {
      path: `${privatePaths.superuser.merchantDetails}/*`,
      Component: <SuperuserMerchantDetails />
    },
    {
      path: privatePaths.superuser.franchiseeCreate,
      Component: <SuperuserFranchiseeCreate />
    },
    {
      path: privatePaths.superuser.contactManager,
      Component: <SuperuserContactManager />
    },
    {
      path: `${privatePaths.superuser.wallet}/*`,
      Component: <SuperuserWallet />
    },
    {
      path: privatePaths.superuser.sales,
      Component: <SuperuserSales />
    },
    {
      path: privatePaths.superuser.gifts,
      Component: <SuperuserGifts />
    },
    {
      path: privatePaths.superuser.giftDetails,
      Component: <SuperuserGiftDetails/>
    },
    {
      path: `${privatePaths.superuser.contactManagerDetail}/*`,
      Component: <ContactManagerTabsPage />
    },
    {
      path: privatePaths.superuser.contactManagerMerchantDetail,
      Component: <ContactManagerMerchantDetail />
    },
    {
      path: `${privatePaths.superuser.contactManagerSubfranchiseeDetail}/*`,
      Component: <ContactManagerSubFranchiseeDetail />
    },
    {
      path: privatePaths.superuser.financials,
      Component: <SuperuserFinancials />
    },
    {
      path: `${privatePaths.superuser.financialsDetail}/*`,
      Component: <SuperuserFinancialsTabsPage />
    },
    {
      path: `${privatePaths.superuser.franchiseeDetail}/*`,
      Component: <FranchiseeTabsPage />
    },
    {
      path: `${privatePaths.superuser.subfranchiseeDetail}/*`,
      Component: <SubfranchiseeDetailPage />
    },
    {
      path: privatePaths.superuser.merchantDetail,
      Component: <MerchantDetail />
    },
    {
      path: privatePaths.superuser.subFranMerchantDetail,
      Component: <MerchantDetail />
    },
    {
      path: privatePaths.superuser.appSettings,
      Component: <SuperuserAppSettings />
    },
    {
      path: `${privatePaths.superuser.profile}`,
      Component: <ProfilePage />
    },
    {
      path: '*',
      Component: <NotExistingPage />
    }
  ],
  merchant: [
    {
      path: `${privatePaths.merchant.businessInfo}/`,
      Component: <BusinessInfoPage />
    },
    {
      path: `${privatePaths.merchant.gifts}/*`,
      Component: <GiftsPage />
    },
    {
      path: privatePaths.merchant.giftClaim,
      Component: <GiftClaimPage />
    },
    {
      path: `${privatePaths.merchant.fileFolder}/*`,
      Component: <FileFolderPage />
    },
    {
      path: `${privatePaths.merchant.wallet}/*`,
      Component: <MerchantWalletPage />
    },
    {
      path: `${privatePaths.merchant.appSettings}/*`,
      Component: <AppSettingsPage />
    },
    {
      path: `${privatePaths.merchant.createPromo}`,
      Component: <CreatePromoPage />
    },
    {
      path: `${privatePaths.merchant.createGift}`,
      Component: <CreateGiftProfile />
    },
    {
      path: `${privatePaths.merchant.createGiftByPhotoId}`,
      Component: <CreateGiftProfile />
    },
    {
      path: `${privatePaths.merchant.giftDetails}`,
      Component: <GiftDetailsPage />
    },
    {
      path: `${privatePaths.merchant.promotionDetails}`,
      Component: <PromotionDetailsPage />
    },
    {
      path: privatePaths.merchant.profile,
      Component: <MerchantProfilePage />
    },
    {
      path: '*',
      Component: <NotExistingPage />
    }
  ],
  shopper: [
    {
      path: privatePaths.shopper.profile,
      Component: <ClientProfilePage />
    }
  ],
  member: [
    {
      path: privatePaths.member.profile,
      Component: <ClientProfilePage />
    }
  ],
  ogmember: [
    {
      path: privatePaths.ogmember.profile,
      Component: <ClientProfilePage />
    }
  ]
}

const App = () => {
  const {
    user: { type }
  } = authStore
  const role = localStorage.getItem('role') === 'member' || localStorage.getItem('role') === 'shopper' || localStorage.getItem('role') === 'ogmember'
  ?localStorage.getItem('role')
  : type?.replace(/\s/g, '')?.toLowerCase() || 'superuser'

  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        {privateRoutes?.[role]?.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={<PrivateRoute>{route.Component}</PrivateRoute>}
          />
        ))}
        {publicRoutes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={<PublicRoute>{route.Component}</PublicRoute>}
          />
        ))}
        <Route path="*" element={<Navigate to={publicPaths.login} replace />} />
        <Route
          exact
          path="/"
          element={<Navigate to={publicPaths.login} replace />}
        />
      </Routes>
    </Suspense>
  )
}

export default observer(App)
