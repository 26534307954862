import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'core'
import reportWebVitals from './reportWebVitals'
import theme from 'config/theme'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Notification from 'components/Notification'
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Notification />
      <App />
      <Helmet>
        <title>OGI Gifts</title>
        <meta name="description" content="OGI Gifts back office" />
      </Helmet>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
