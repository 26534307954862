import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#41A0D2',
      minor: '#282A3A',
      dark: '#282A3A',
      sidebar: '#FAFAFA',
      darkText: '#1B212D',
      labelDarkGrey: '#9496A1',
      csvHover: '#FAFAFA',
      labelLightGrey: '#F3F4F8',
      itemsBorderBottom: '#ECECEC',
      disabledText: '#9B9B9B',
      disabledColor: '#F8F8F8',
      disabledBorder: '#F3F3F3'
    },
    secondary: {
      main: '#FDA163',
      minor: '#59E066',
      error: '#E05D59',
      blue: '#9DCBF5',
      grey: '#929EAE',
      white: '#FFFFFF',
      lightGrey: '#D2D4DA',
      darkGrey: '#78778B',
      black: '#000000',
      light: '#F5F5F5',
      errorBg: '#FFEFEF',
      activeBg: '#D9FFE9',
      pendingBg: '#FFF1E6',
      unactiveBg: '#D2D4DA',
      labelBgGrey: '#F3F4F8',
      tableStatusOrange: '#FDA76D'
    },
    effects: {
      shadow: '0px 1px 4px rgba(33, 44, 52, 0.1)',
      blur: 'blur(5px)'
    },
    notifications: {
      successBackground: '#F0FFF1',
      successBorder: '#59E066',
      shadow: '0px 2px 15px rgba(33, 33, 52, 0.1)',
      warningBackground: '#FFF0F0',
      warningBorder: '#E05D59'
    },
    transaction: {
      debit: '#FF232E',
      credit: '#008E07'
    },
    status: {
      pending: '#FDA163',
      backPending: '#FFF1E5',
      active: '#ffff',
      backActive: '#59E066',
      unverified: '#E05D59',
      backUnverified: '#FFEFEF',
      verified: '#ffff',
      backVerified: '#a9b922'
    },
    radioButton: {
      inActive: '#DFDFDF',
      active: '#41A0D2'
    }
  },
  typography: {
    fontFamily: ['Gilroy', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    button: {
      fontSize: '1.4rem',
      fontWeight: '500'
    },
    h1: {
      fontSize: '4.8rem',
      color: '#282A3A'
    },
    h3: {
      fontSize: '2.4rem',
      color: '#282A3A'
    },
    h4: {
      fontSize: '2rem',
      color: '#282A3A'
    },
    h5: {
      fontSize: '1.6rem',
      color: '#282A3A'
    },
    h6: {
      fontSize: '1.4rem',
      color: '#282A3A'
    },
    body1: {
      fontSize: '1.2rem'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: 10
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: '#41A0D2',
            color: '#fff'
          },
          '&.MuiToggleButton-root.Mui-disabled': {
            boxSizing: 'border-box',
            padding: '.2rem 2.4rem',
            borderTopRightRadius: '.4rem !important',
            borderTopLeftRadius: '.4rem !important',
            borderBottomRightRadius: '.4rem !important',
            borderBottomLeftRadius: '.4rem !important',
            lineHeight: '2rem',
            border: 'none',
            textTransform: 'none',
            width: 'max-content'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          fontSize: '1.4rem',
          fontWeight: 500,
          borderRadius: 8,
          textTransform: 'none',
          '&:hover': {
            background: '#41A0D2'
          },
          ':disabled': {
            background: '#D2D4DA',
            color: '#FFFFFF'
          }
        },
        outlined: {
          color: '#41A0D2',
          '&:hover': {
            background: '#ffff'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          background: '#E05D59',
          color: '#fff',
          borderRadius: 8,
          '& svg': {
            width: '2.2rem',
            height: '2.2rem'
          },
          '&:hover': {
            background: '#E05D59'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input': {
            fontSize: 14
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D2D4DA !important',
            borderWidth: '1px !important'
          },
          '& .Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#41A0D2 !important',
              borderWidth: '1px !important'
            }
          },
          '& .Mui-error': {
            color: '#E05D59',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#E05D59 !important',
              borderWidth: '1px !important'
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '3.9rem',
          width: '18.9rem',
          borderRadius: '0.5rem'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#DFDFDF',
          '&.Mui-checked': {
            color: '#41A0D2'
          }
        }
      }
    }
  }
})

export default theme
