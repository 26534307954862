import { privatePaths } from 'config/routes'
import SubFranchiseesIcon from 'assets/svg/SubFranshiseesIcon'
import ShopIcon from 'assets/svg/ShopIcon'
import GiftIcon from 'assets/svg/GiftIcon'
import PeopleIcon from 'assets/svg/PeopleIcon'
import NotificationIcon from 'assets/svg/NotificationIcon'
import MailIcon from 'assets/svg/MailIcon'
import SettingsIcon from 'assets/svg/SettingsIcon'
import AdminAvatar from 'assets/svg/AdminAvatar'
import FranchiseeAvatar from 'assets/svg/FranchiseeAvatar'
import SubFranchiseeAvatar from 'assets/svg/SubFranshiseeAvatar'
import MerchantAvatar from 'assets/svg/MerchantAvatar'
import FileFolderIcon from 'assets/svg/FileFolderIcon'
import WalletIcon from 'assets/svg/WalletIcon'
import BusinessInfoIcon from 'assets/svg/BusinessInfoIcon'
import ProfileIcon from 'assets/svg/AdminAvatar'
import SalesIcon from 'assets/svg/SalesIcon'

export const menuRoutes = disabled => ({
  member: [
    {
      id: 'profile',
      title: 'Profile',
      icon: <ProfileIcon />,
      path: privatePaths.member.profile
    }
  ],
  shopper: [
    {
      id: 'profile',
      title: 'Profile',
      icon: <ProfileIcon />,
      path: privatePaths.shopper.profile
    }
  ],
  ogmember: [
    {
      id: 'profile',
      title: 'Profile',
      icon: <ProfileIcon />,
      path: privatePaths.ogmember.profile
    }
  ],
  franchisee: [
    {
      id: 'sub_franchisees',
      title: 'SubFranchisees',
      icon: <SubFranchiseesIcon />,
      path: privatePaths.franchisee.sub_franchisees,
      cleanFilters: true
    },
    {
      id: 'merchant',
      title: 'Merchant',
      icon: <ShopIcon />,
      path: `${privatePaths.franchisee.merchant}/allMerchants`,
      cleanFilters: true
    },
    {
      id: 'all_gifts',
      title: 'All Gifts',
      icon: <GiftIcon />,
      path: `${privatePaths.franchisee.allGifts}/allGifts`
    },
    {
      id: 'shoppers_members',
      title: 'Shoppers/Members',
      icon: <PeopleIcon />,
      path: privatePaths.franchisee.shoppersMembers,
      cleanFilters: true
    },
    {
      id: 'push_notifications',
      title: 'Push Notifications',
      icon: <NotificationIcon />,
      path: `${privatePaths.franchisee.pushNotifications}/notification_form`
    },
    {
      id: 'mass_email',
      title: 'Mass Email',
      icon: <MailIcon />,
      path: `${privatePaths.franchisee.massEmail}/mass_email_form`
    },
    {
      id: 'translations',
      title: 'Translations',
      icon: <NotificationIcon />,
      path: `${privatePaths.franchisee.translations}/login_page`
    },
    {
      id: 'wallet',
      title: 'Wallet',
      icon: <WalletIcon />,
      path: `${privatePaths.franchisee.wallet}/withdrawalRequests`
    },
    {
      id: 'profile',
      title: 'Profile',
      icon: <ProfileIcon />,
      path: `${privatePaths.franchisee.profile}`
    },
    {
      id: 'app_settings',
      title: 'App Settings',
      icon: <SettingsIcon />,
      path: `${privatePaths.franchisee.appSettings}/payment_options`
    }
  ],
  subfranchisee: [
    {
      id: 'merchant',
      title: 'Merchant',
      icon: <ShopIcon />,
      path: `${privatePaths.subfranchisee.merchant}/allMerchants`,
      cleanFilters: true
    },
    {
      id: 'gifts',
      title: 'Gifts',
      icon: <GiftIcon />,
      path: `${privatePaths.subfranchisee.gifts}allGifts`
    },
    // {
    //   id: 'shoppers_members',
    //   title: 'Shoppers/Members',
    //   icon: <PeopleIcon />,
    //   path: privatePaths.subfranchisee.shoppersMembers
    // },
    // {
    //   id: 'push_notifications',
    //   title: 'Push Notifications',
    //   icon: <NotificationIcon />,
    //   path: `${privatePaths.subfranchisee.pushNotifications}/notification_form`
    // },
    // {
    //   id: 'mass_email',
    //   title: 'Mass Email',
    //   icon: <MailIcon />,
    //   path: privatePaths.subfranchisee.massEmail
    // },
    {
      id: 'profile',
      title: 'Profile',
      icon: <ProfileIcon />,
      path: `${privatePaths.subfranchisee.profile}`
    }
  ],
  superuser: [
    {
      id: 'contact_manager',
      title: 'Contact Manager',
      icon: <PeopleIcon />,
      path: privatePaths.superuser.contactManager,
      cleanFilters: true
    },
    {
      id: 'sales',
      title: 'Sales',
      icon: <SalesIcon />,
      path: privatePaths.superuser.sales,
      cleanFilters: true
    },
    {
      id: 'gifts',
      title: 'Gifts',
      icon: <GiftIcon />,
      path: privatePaths.superuser.gifts,
      cleanFilters: true
    },
    {
      id: 'franchisee',
      title: 'Franchisee',
      icon: <SubFranchiseesIcon />,
      path: privatePaths.superuser.franchisee
    },
    {
      id: 'merchants',
      title: 'Merchants',
      icon: <ShopIcon />,
      path: `${privatePaths.superuser.merchants}/allMerchants`,
      cleanFilters: true
    },
    {
      id: 'profile',
      title: 'Profile',
      icon: <ProfileIcon />,
      path: privatePaths.superuser.profile
    },
    {
      id: 'wallet',
      title: 'Wallet',
      icon: <WalletIcon />,
      path: `${privatePaths.superuser.wallet}/rechargeSuperuserWallet`
    },
    {
      id: 'financials',
      title: 'Financials',
      icon: <WalletIcon />,
      path: privatePaths.superuser.financials
    },
    {
      id: 'app_settings',
      title: 'App Settings',
      icon: <SettingsIcon />,
      path: privatePaths.superuser.appSettings
    }
  ],
  merchant: [
    {
      id: 'business_info',
      title: 'Business Info',
      icon: <BusinessInfoIcon />,
      path: privatePaths.merchant.businessInfo
    },
    {
      id: 'gifts',
      title: 'Gifts',
      icon: <GiftIcon />,
      path: `${privatePaths.merchant.gifts}/allGifts`,
      disabled: disabled
    },
    {
      id: 'giftsClaim',
      title: 'Gifts Claim',
      icon: <GiftIcon />,
      path: privatePaths.merchant.giftClaim,
      disabled: disabled
    },
    {
      id: 'file_folder',
      title: 'File Folder',
      icon: <FileFolderIcon />,
      path: `${privatePaths.merchant.fileFolder}/allPhotos`,
      disabled: disabled
    },
    {
      id: 'wallet',
      title: 'Wallet',
      icon: <WalletIcon />,
      path: `${privatePaths.merchant.wallet}/details`,
      disabled: disabled
    },
    {
      id: 'password_change',
      title: 'Password change',
      icon: <ProfileIcon />,
      path: privatePaths.merchant.profile
    },
    {
      id: 'app_settings',
      title: 'App Settings',
      icon: <SettingsIcon />,
      path: `${privatePaths.merchant.appSettings}/users`,
      disabled: disabled
    }
  ]
})

export const avatars = {
  superuser: <AdminAvatar />,
  franchisee: <FranchiseeAvatar />,
  subfranchisee: <SubFranchiseeAvatar />,
  merchant: <MerchantAvatar />
}
