import theme from 'config/theme'

export const getStyles = () => {
  return {
    container: {
      display: 'flex'
    },
    menu: {
      width: '25rem',
      background: theme.palette.primary.sidebar,
      minHeight: '100vh',
      padding: '3rem 2.5rem 10rem',
      display: 'flex',
      flexDirection: 'column'
    },
    headerWithContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '85%'
    },
    header: {
      height: '10rem',
      display: 'flex',
      padding: '3rem 4rem 2.1rem 4rem',
      width: '100%',
      alignItems: 'center'
    },
    content: {
      padding: '0 4rem'
    },
    headerTitle: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      '& .mainTitle': {
        fontWeight: 600
      },

      '& .titleDetails': {
        wordBreak: 'break-word'
      }
    },
    backIcon: {
      width: '0.6rem',
      height: '1.2rem',
      cursor: 'pointer',
      marginRight: '1.8rem'
    },
    userInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      background: theme.palette.primary.sidebar,
      borderRadius: '10rem',
      padding: '0.6rem 8.9rem 0.6rem 1.2rem',
      width: '20.3rem',
      height: '4.8rem',
      '& svg': {
        width: '3.6rem',
        height: '3.6rem'
      }
    },
    dropdownIconClosed: {
      fill: theme.palette.primary.darkText,
      cursor: 'pointer'
    },
    dropdownIconOpen: {
      fill: theme.palette.primary.darkText,
      transform: 'rotate(180deg)',
      cursor: 'pointer'
    },
    userMenuWrapper: {
      '& .MuiPaper-root  ': {
        border: `0.1rem solid ${theme.palette.primary.disabledBorder}`,
        borderTop: 'none',
        width: '20.04rem',
        borderTopLeftRadius: '0rem !important',
        borderTopRightRadius: '0rem !important',
        borderRadius: '1rem',
        '& ul': {
          padding: '1.2rem 1.8rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.2rem',
          '& div': {
            display: 'flex',
            gap: '1.8rem',
            '& p': {
              display: 'block'
            },
            '& p:first-of-type': {
              width: '40%',
              lineHeight: '2rem'
            },
            '& p:last-child': {
              color: theme.palette.primary.dark,
              lineHeight: '2.2rem'
            }
          }
        }
      }
    },

    sideBarLogo: {
      width: '11.6rem',
      height: '4rem',
      marginBottom: '4.5rem'
    },
    sideBarContent: {
      flex: 1
    },
    logout: {
      display: 'flex',
      gap: '1.2rem',
      alignItems: 'center',
      padding: '1.4rem 8.1rem 1.4rem 1.5rem',
      cursor: 'pointer',
      '& svg': {
        width: '2rem',
        height: '2rem'
      },
      '& h6': {
        fontWeight: 500,
        color: theme.palette.secondary.grey,
        whiteSpace: 'nowrap'
      }
    }
  }
}

export const getMenuItemStyles = (active, disabled) => {
  return {
    wrapper: {
      display: 'flex',
      gap: '1.2rem',
      alignItems: 'center',
      background: active ? theme.palette.primary.main : 'none',
      padding: '1.4rem 8.1rem 1.4rem 1.5rem',
      borderRadius: '0.8rem',
      cursor: 'pointer',
      '& svg': {
        width: '2rem',
        height: '2rem',
        fill: active
          ? theme.palette.secondary.white
          : disabled
          ? theme.palette.secondary.lightGrey
          : theme.palette.secondary.grey
      },
      '& h6': {
        fontWeight: 500,
        color: active
          ? theme.palette.secondary.white
          : disabled
          ? theme.palette.secondary.lightGrey
          : theme.palette.secondary.grey,
        whiteSpace: 'nowrap'
      }
    },
    disabled: {}
  }
}
