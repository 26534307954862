import axios from 'axios'
import LoginStore from 'pages/LoginPage/store'
import Qs from 'qs'

const {
  REACT_APP_BASE_URL,
  REACT_APP_PORT,
  REACT_APP_PROXY,
  REACT_APP_APP_PROXY,
  REACT_APP_PROXY_V2
} = process.env

const BASE_URL = `${REACT_APP_BASE_URL}${
  REACT_APP_PORT ? `:${REACT_APP_PORT}` : ''
}${REACT_APP_PROXY}`

const BASE_APP_URL = `${REACT_APP_BASE_URL}${
  REACT_APP_PORT ? `:${REACT_APP_PORT}` : ''
}${REACT_APP_APP_PROXY}`

const BASE_URL_V2 = `${REACT_APP_BASE_URL}${
  REACT_APP_PORT ? `:${REACT_APP_PORT}` : ''
}${REACT_APP_PROXY_V2}`

let isRefreshing = false
let refreshPromise = null
const requestQueue = []

const instance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    Authorization: `Bearer *token*`
  },
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' })
})

const getRefreshToken = async () => {
  try {
    const token = localStorage.getItem('refresh_token')
    const response = await publicAxios.post(
      '/auth/refresh-token',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    localStorage.setItem('access_token', response.data.access_token)
    localStorage.setItem('refresh_token', response.data.refresh_token)
    isRefreshing = false
    refreshPromise = null

    requestQueue.forEach(requestCallback => {
      const newAccessToken = response.data.access_token
      requestCallback(newAccessToken)
    })
    requestQueue.length = 0

    return response.data
  } catch (error) {
    if (error.response.status === 401) {
      LoginStore.logout()
    }
  }
}

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  res => {
    return res
  },
  async err => {
    const originalConfig = err.config

    if (err.response && err.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true
        refreshPromise = getRefreshToken()
      }

      const retryWithNewToken = new Promise(resolve => {
        requestQueue.push(newAccessToken => {
          originalConfig.headers.Authorization = `Bearer ${newAccessToken}`
          resolve(axios(originalConfig))
        })
      })

      return retryWithNewToken
    }
    return Promise.reject(err)
  }
)

const publicAxios = axios.create({
  baseURL: `${BASE_URL}`,
  headers: { 'Content-Type': 'multipart/form-data' }
})

const publicAPPAxios = axios.create({
  baseURL: `${BASE_APP_URL}`,
  headers: { 'Content-Type': 'multipart/form-data' }
})

const axios_v2 = axios.create({
  baseURL: `${BASE_URL_V2}`,
  headers: {
    Authorization: `Bearer *token*`
  },
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' })
})

axios_v2.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error)
)

axios_v2.interceptors.response.use(
  res => {
    return res
  },
  async err => {
    const originalConfig = err.config

    if (err.response && err.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true
        refreshPromise = getRefreshToken()
      }

      const retryWithNewToken = new Promise(resolve => {
        requestQueue.push(newAccessToken => {
          originalConfig.headers.Authorization = `Bearer ${newAccessToken}`
          resolve(axios(originalConfig))
        })
      })

      return retryWithNewToken
    }
    return Promise.reject(err)
  }
)

export { publicAxios, publicAPPAxios, BASE_URL, axios_v2 }

export default instance
