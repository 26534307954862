import { SvgIcon } from '@mui/material'

const LogoutIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 20" {...props}>
      <path
        d="M6.56662 10.0583C6.56662 9.71667 6.84995 9.43334 7.19162 9.43334H11.7583V2.38334C11.75 1.98334 11.4333 1.66667 11.0333 1.66667C6.12495 1.66667 2.69995 5.09167 2.69995 10C2.69995 14.9083 6.12495 18.3333 11.0333 18.3333C11.425 18.3333 11.75 18.0167 11.75 17.6167V10.675H7.19162C6.84162 10.6833 6.56662 10.4 6.56662 10.0583Z"
        fill="#929EAE"
      />
      <path
        d="M17.1167 9.61668L14.75 7.24167C14.5083 7.00001 14.1083 7.00001 13.8667 7.24167C13.625 7.48334 13.625 7.88334 13.8667 8.12501L15.1667 9.42501H11.75V10.675H15.1583L13.8583 11.975C13.6167 12.2167 13.6167 12.6167 13.8583 12.8583C13.9833 12.9833 14.1417 13.0417 14.3 13.0417C14.4583 13.0417 14.6167 12.9833 14.7417 12.8583L17.1083 10.4833C17.3583 10.25 17.3583 9.85834 17.1167 9.61668Z"
        fill="#929EAE"
      />
    </SvgIcon>
  )
}

export default LogoutIcon
