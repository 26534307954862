import { makeObservable, observable, action } from 'mobx';

class FiltersStore {
  activeFilters = [];
  params = {};
  checkboxStates = {
    created_time: false,
    last_login_time: false,
    country: false,
    status: false,
    role: false,
    username: false,
    sponsor_id: false,
    ogi_id: false,
    user_type: false,
  };

  constructor() {
    makeObservable(this, {
      activeFilters: observable.ref,
      params: observable,
      checkboxStates: observable,
      setActiveFilters: action,
      cleanFilters: action,
      removeFilter: action,
      updateCheckboxState: action,
      forceUpdate: action,
      updateDateRange: action,
      resetAllFilters: action,
      clearFilters: action,
    });
  }

  setActiveFilters = filters => {
    this.activeFilters = filters;
    let createParams = {};
    if (Boolean(this.activeFilters.filter(item => item.name).length)) {
      createParams = this.activeFilters.reduce((acc, cur) => {
        return { ...acc, [cur.name]: cur.value };
      }, {});
    } else {
      this.activeFilters.forEach(item => {
        createParams[Object.keys(item)[0]] = item[Object.keys(item)[0]];
      });
    }
    this.params = createParams;
  };

  cleanFilters = () => {
    this.params = {};
    this.activeFilters = [];
  };

  resetAllFilters = () => {
    this.activeFilters = [];
    this.params = {};
    Object.keys(this.checkboxStates).forEach(key => {
      this.checkboxStates[key] = false;
    });
    localStorage.removeItem('userFilters');
    localStorage.removeItem('adminSalesFilters');
  };

  handleBeforeUnload = () => {
    this.resetAllFilters();
  };

  removeFilter = key => {
    this.activeFilters = this.activeFilters.filter(filter => !filter.hasOwnProperty(key));
    delete this.params[key];

    const checkboxMap = {
      verified_date_range: 'created_time',
      user_status: 'status',
      country_filter: 'country',
      user_type: 'role',
      username: 'username',
      sponsor_id: 'sponsor_id',
      ogi_id: 'ogi_id',
      last_login_date_range: 'last_login_time',
      created_date_range: 'created_time',
      chronology_range: 'created_time',
      date_range: 'date_range',
    };

    const checkboxKey = checkboxMap[key] || key;
    this.updateCheckboxState(checkboxKey, false);
  };

  forceUpdate = () => {
    this.activeFilters = [...this.activeFilters];
  }


  updateLocalStorage = () => {
    const savedFilters = JSON.parse(localStorage.getItem('userFilters') || '{}');
    savedFilters.checkbox = this.checkboxStates;
    localStorage.setItem('userFilters', JSON.stringify(savedFilters));
  };

  updateCheckboxState = (key, value) => {
    this.checkboxStates[key] = value;
    this.updateLocalStorage();
  };

  syncVerifiedDateToCreatedDate = (fromDate, toDate) => {
    const dateRange = [fromDate, toDate];
    localStorage.setItem('verified_date_range', JSON.stringify(dateRange));
  };

  updateDateRange = (fromDate, toDate) => {
    this.params = { ...this.params, date_range: fromDate };

    if (toDate) {
      this.params.date_range = toDate;
      this.activeFilters.push({ date_range: toDate });
    }

    this.activeFilters = this.activeFilters.filter(filter => !filter.hasOwnProperty('date_range'));
    this.activeFilters.push({ date_range: fromDate });

    this.updateLocalStorage();
  };

  getVerifiedDateRange = () => {
    const savedDateRange = localStorage.getItem('verified_date_range');
    return savedDateRange ? JSON.parse(savedDateRange) : null;
  };

  clearVerifiedDateRange = () => {
    localStorage.removeItem('verified_date_range');
  };

  clearFilters = () => {
    this.activeFilters = [];
    this.params = {};
    this.checkboxStates = {
      created_time: false,
      last_login_time: false,
      country: false,
      status: false,
      role: false,
      username: false,
      sponsor_id: false,
      ogi_id: false,
      user_type: false,
    };
    this.updateLocalStorage();
  };
}

const filtersStore = new FiltersStore();
window.addEventListener('beforeunload', filtersStore.handleBeforeUnload);

export default new FiltersStore();
