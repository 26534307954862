import { SvgIcon } from '@mui/material'

const SalesIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.03363 23.363C8.55231 23.5941 7.97435 23.4063 7.72078 22.9364L6.15806 20.0406L2.92109 19.4492C2.39586 19.3533 2.03866 18.8616 2.1097 18.3325L2.54754 15.0712L0.276386 12.6901C-0.0921287 12.3037 -0.0921287 11.696 0.276386 11.3097L2.54754 8.9286L2.1097 5.66731C2.03866 5.13814 2.39586 4.64649 2.92109 4.55054L6.15806 3.95915L7.72078 1.06336C7.97435 0.593489 8.55231 0.405698 9.03363 0.636787L12 2.06098L14.9664 0.636787C15.4477 0.405698 16.0257 0.593489 16.2792 1.06336L17.8419 3.95915L21.0789 4.55054C21.6041 4.64649 21.9613 5.13814 21.8903 5.66731L21.4525 8.9286L23.7236 11.3097C24.0921 11.696 24.0921 12.3037 23.7236 12.6901L21.4525 15.0712L21.8903 18.3325C21.9613 18.8616 21.6041 19.3533 21.0789 19.4492L17.8419 20.0406L16.2792 22.9364C16.0257 23.4063 15.4477 23.5941 14.9664 23.363L12 21.9388L9.03363 23.363ZM10.2071 15.207C9.81658 15.5975 9.18342 15.5975 8.79289 15.207C8.40237 14.8165 8.40237 14.1833 8.79289 13.7928L13.7929 8.79278C14.1834 8.40226 14.8166 8.40226 15.2071 8.79278C15.5976 9.18331 15.5976 9.81647 15.2071 10.207L10.2071 15.207ZM9.5 10.9999C8.67157 10.9999 8 10.3283 8 9.49989C8 8.67146 8.67157 7.99989 9.5 7.99989C10.3284 7.99989 11 8.67146 11 9.49989C11 10.3283 10.3284 10.9999 9.5 10.9999ZM14.5 15.9999C13.6716 15.9999 13 15.3283 13 14.4999C13 13.6715 13.6716 12.9999 14.5 12.9999C15.3284 12.9999 16 13.6715 16 14.4999C16 15.3283 15.3284 15.9999 14.5 15.9999Z"
      />
    </SvgIcon>
  )
}

export default SalesIcon
