import { SvgIcon } from '@mui/material'

const SubFranchiseesIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 20" {...props}>
      <path d="M7.50008 1.66666C5.31675 1.66666 3.54175 3.44166 3.54175 5.62499C3.54175 7.76666 5.21675 9.49999 7.40008 9.57499C7.46675 9.56666 7.53341 9.56666 7.58342 9.57499C7.60008 9.57499 7.60841 9.57499 7.62508 9.57499C7.63341 9.57499 7.63341 9.57499 7.64175 9.57499C9.77508 9.49999 11.4501 7.76666 11.4584 5.62499C11.4584 3.44166 9.68341 1.66666 7.50008 1.66666Z" />
      <path d="M11.7333 11.7917C9.4083 10.2417 5.61663 10.2417 3.27497 11.7917C2.21663 12.5 1.6333 13.4583 1.6333 14.4833C1.6333 15.5083 2.21663 16.4583 3.26663 17.1583C4.4333 17.9417 5.96663 18.3333 7.49997 18.3333C9.0333 18.3333 10.5666 17.9417 11.7333 17.1583C12.7833 16.45 13.3666 15.5 13.3666 14.4667C13.3583 13.4417 12.7833 12.4917 11.7333 11.7917Z" />
      <path d="M16.6583 6.11668C16.7916 7.73335 15.6416 9.15001 14.05 9.34168C14.0416 9.34168 14.0416 9.34168 14.0333 9.34168H14.0083C13.9583 9.34168 13.9083 9.34168 13.8666 9.35834C13.0583 9.40001 12.3166 9.14168 11.7583 8.66668C12.6166 7.90001 13.1083 6.75001 13.0083 5.50001C12.95 4.82501 12.7166 4.20835 12.3666 3.68335C12.6833 3.52501 13.05 3.42501 13.425 3.39168C15.0583 3.25001 16.5166 4.46668 16.6583 6.11668Z" />
      <path d="M18.3251 13.825C18.2584 14.6333 17.7418 15.3333 16.8751 15.8083C16.0418 16.2667 14.9918 16.4833 13.9501 16.4583C14.5501 15.9167 14.9001 15.2417 14.9668 14.525C15.0501 13.4917 14.5584 12.5 13.5751 11.7083C13.0168 11.2667 12.3668 10.9167 11.6584 10.6583C13.5001 10.125 15.8168 10.4833 17.2418 11.6333C18.0084 12.25 18.4001 13.025 18.3251 13.825Z" />
    </SvgIcon>
  )
}

export default SubFranchiseesIcon
